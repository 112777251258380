.Menu {
  width: 100%;
  height: 100%;
  max-height: 90vh;
  overflow-y: scroll;
  text-align: left;
}


.MenuItem {
  color: black;
  padding: 1em;
  white-space: nowrap;
}

.Menu[wide='true'] .MenuItem {
  padding-left: 2em;
}


.MenuItem:hover {
  background-color: #EFEBFF;
}