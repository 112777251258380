.Action {
  display: inline-flex;
/*  background-color: #ffffff;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.1);*/
  border-radius: 3px;
  box-sizing: border-box;
  margin-left: 1em;
  text-align: center;
  cursor: pointer;
  user-select: none;
  align-items: center;
}

.ActionIcon {
  width: 30px;
  font-size: 1.2em;
  display: inline-block;
}

.ActionName {
  font-size: 0.9em;
  color: #777777;
  vertical-align: top;
}

.Action[undo='true'] .ActionName,
.Action[undo='true'] .ActionIcon {
  opacity: 0.5;
}

.Action[undo='true'] .ActionName {
  text-decoration: line-through;
/*  color: rgba(255,0,0,0.75);*/
}

.Action:hover .ActionName {
  color: black;
}

.Action[undo='true']:hover .ActionName {
/*  color: rgba(255,0,0,1);*/
}

.ActionShortcut {
  font-size: 0.9em;
  color: rgba(0,0,0,0.35);
  background-color: rgba(0,0,0,0.05);
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 6px;
  margin-right: 12px;
  margin-top: -5px;
  vertical-align: middle;
  line-height: 16px;
  text-align: center;
  display: none;
}

.Action:hover .ActionShortcut {
  color: rgba(0,0,0,0.7);
  background-color: rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
}


@media only screen and (max-width: 480px) {
  .ActionName {
    display: none;
  } 
}
