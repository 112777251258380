.OptionChips {
  display: inline-flex;
  user-select: none;
}

.OptionChipGroup {
  display: inline-flex;
  padding-right: 0.75em;
}

.SoloOptionChipGroup {
  display: inline-flex;
}