.Week {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.Week .BeforeWeek {
  border-left: 1px solid rgba(0,0,0,0.05);
}

.Week:not(:last-child) .AfterWeek {
  width: 0px;
}

.Week:last-child .AfterWeek {
  border-left: 1px solid rgba(0,0,0,0.05);
  border-right: 1px solid rgba(0,0,0,0.05);
  width: 7px;
  background: #E0E0E255;
}

.Week .BeforeWeek {
  width: 7px;
  background: #E0E0E255;
}