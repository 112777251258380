.Chip {
  display: inline-flex;
  margin-left: 0em;
  margin-right: 0.5em;
  cursor: pointer;
  height: 30px;
  background-color: clear;
  padding-left: 0.9em;
  padding-right: 0.9em;
  border-radius: 1em;
  border: 1px solid rgba(0,0,0,0.15);
  box-sizing: border-box;
  user-select: none;
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 0.875em;
  position: relative;
  align-items: center;
}

.Chip:last-child {
  margin-right: 0em;
}


.Chip[isselectable='false'] {
  /* Account for border disappearing */
  height: 32px;
  padding-left: calc(1em + 1px);
  padding-right: calc(1em + 1px);
  border: none;
}

.Chip[isblank='true'] {
  color: rgba(0,0,0,0.15);
}

.Chip[isselected='true'] {
  background-color: #DFD6FF;
  border: 1px solid  #DFD6FF;
  color: white;
  color: #7E57FF;
}

.Chip[issmall='true'] {
  height: 24px;
  display: inline-flex;
  border-radius: 20px;
  padding-left: 0.7em;
  padding-right: 0.7em;
  font-size: 0.9em;
  margin-bottom: 0.1em;
  /*margin-left: 0.75em;
  margin-right: 0em;*/
}


.Chip[isiconic='true'] {
  padding: 0.1em 0.1em 0em 0em;
  text-align: center;
  vertical-align: middle;
  line-height: 1.9em;
  width: 2em;
  height: 1.9em;
  border-radius: 100%;
  border: 1px solid rgba(0,0,0,0.15);
  color: black;
}

.Chip[isiconic='true'][isdimmable='true'] {
  border: 1px solid rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.5);
}

.Chip[isiconic='true'][isdimmable='true'][isselected='true'] {
  background-color: #DFD6FF;
  border: 1px solid  #DFD6FF;
  color: black;
}

.Chip[isiconic='true'][isselected='true'] {
  background-color: #DFD6FF;
  border: 1px solid  #DFD6FF;
}

.Chip[ismultiselectable='true'] {
  background-color: rgba(0,0,0,0.07);
  color:#777777;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border: none;
}

.Chip span {
  font-size: 0.95em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Chip[ismultiselectable='true'] span {
  opacity: 0.5;
}

.Chip[ismultiselectable='true'][isselected='true'] {
  background-color: #DFD6FF;
  color: #000000;
  color: #7E57FF;
}

.Chip[ismultiselectable='true'][isselected='true'] span {
  opacity: 1.0;
}


/*
.Chip[istoggleable='true'] {
  background-color: rgba(0,0,0,0.07);
  border: none;
  color: #BBBBBB;
}

.Chip[istoggleable='true'][isselected='true'] {
  background-color: #7E57FF;
  border: none;
  color: white;
}
*/

.Chip[isradio='true'] {
  border-radius: 0em;
  margin: 0em;
}

.Chip[isradio='true']:first-child {
  border-radius: 1em 0em 0em 1em;
}

.Chip[isradio='true']:last-child {
  border-radius: 0em 1em 1em 0em;
}

.Chip[isradio='true']:not(:first-child) {
  border-left: none;
}

.Chip[isradio='true'][isselected='true']:not(:first-child) {
  border-left: 1px solid  #DFD6FF;
  margin-left: -1px;
}

.ChipIcon {
  display: flex;
  align-self: center;
}
