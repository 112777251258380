.Chip[bigness='small'] {
  display: inline-block;
  border-radius: 20px;
  background-color: rgba(0,0,0,0.07);
  font-size: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
  line-height: 1.6em;
  margin-left: 0.5em;
  color: #5A5A5A;
  cursor: pointer;
  user-select: none;
}

.Chip[bigness='small'][centered="true"] {
  margin-top: 0.2em;
}

.Chip[selecting="true"] {
  background-color: #DFD6FF;
  color: black;
}

.Chip[bigness='medium'] {
  display: inline-flex;
  padding: 0em 1em 0em 1em;
  height: 24px;
  align-items: center;
  background-color: rgba(0,0,0,0.07);
  border-radius: 2em;
  font-weight: 500;
  line-height: 1em;
  margin-left: 0.5em;
  color: rgba(0,0,0,0.5);
  font-size: 0.95em;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.Chip[bigness='medium'][centered="true"] {
}

.Chip[blank='true'] {
  color: rgba(0,0,0,0.25);
}

.Chip[loading='true'] {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
	transform: scale(1);
	animation: pulse 1s infinite;
}

.Chip[alerting='true'] {
  background-color: rgba(255,0,0,0.07);
  color: rgba(255,0,0,0.25);
}

.Chip[dimmed='true'] {
  background-color: rgba(0,0,0,0.05)
}

.Chip[dimmed='true'] span {
  opacity: 0.8;
}


@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}