.Card {
  margin-bottom: 2em;
  background: white;
  margin-bottom: 1em;
  padding-top: 0em;
  margin-left: 20px;
  border-radius: 10px;
  max-width: 1200px;
  /*box-shadow: 0px 1px 10px rgba(0,0,0,0.1);*/
  box-shadow: 0 1px 1px rgba(0,0,0,0.01), 0 1px 2px rgba(0,0,0,0.033), 0 2px 8px rgba(0,0,0,0.033), 0 2px 16px rgba(0,0,0,0.033), 0 4px 32px rgba(0,0,0,0.066);
}

.CardHeader {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5em;
  padding-left: 20px;
  min-height: 28px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.CardTitles {
}

.CardLink {
  color: black;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
  font-size: 1.1em;
  font-weight: bold;
}

.CardSubtitle {
  color: #AEB6C0;
  font-size: 0.875em;
  font-weight: 500;
  justify-self: left;
  padding-left: 0.75em;
}

.CardIcon {
  display: inline-block;
  font-size: 1.2em;
  opacity: 1;
  cursor: pointer;
  margin-left: -2px;
  margin-right: 11px;
}

.CardIcon:hover {
  animation: growCardIcon 0.75s ease-in-out;
}

@keyframes growCardIcon {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.CardOption {
  display: block;
  justify-self: right;
  align-self: right;
  margin-right: 5px;
  text-align: right;
}

.CardLink:hover {
  color: #7E57FF;
}

.CardContent {
  opacity: 1;
  transition: 0.5s linear;
  border-radius: 0px 0px 10px 10px;
  overflow: clip;
}

.CardContent[loading='true'] {
  opacity: 0;
}

@media only screen and (max-width: 480px) {
  .Card {
    margin-left: 0px;
    border-radius: 0px;
  }
}


