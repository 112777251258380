.Day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  color: #D5D5D5;
  width: 26px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  border-left: 1px solid rgba(0,0,0,0.1);
}

.Day:last-child {
  border-right: 1px solid rgba(0,0,0,0.1);
}

.Day:hover {
  background-color: #DDD6FC88;
  color: #AAAAAA;
}

.Day[scheduled='true'] {
  background-color: #DDD6FC;
  color: #7859F6;
  box-shadow: inset 0 -1px 0 0px rgba(0,0,0,0.1);
}

.Day[scheduled='true']:hover {
  background-color: #D0C6FB;
  color: #623DF5;
}

