.Arena {
  margin-top: 4.2em;
  display: flex;
  flex-direction: row;
}

.MainPane {
  min-width: 1046px;
  width: 1046px;
}

/* Large screen case and default behavior */
.DetailPane {
  position: fixed;
  flex-direction: column;
  left: 1040px;
  width: calc(100vw - 1056px);
  max-width: 1024px;
  height: calc(100vh - 7em);
}

/* Tiny screen case */
@media only screen and (max-width: 1280px) {
  .DetailPane { 
    left: 606px !important;
    width: calc(100vw - 620px) !important;
    min-width: 440px;
    pointer-events: none;
  } 
}

/* Smaller screen case */
@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .MainPane {
    min-width: 1046px;
    max-width: 1046px;
    width: 1046px;
  }
  .DetailPane {
    left: 1040px;
    width: calc(100vw - 1056px);
  }
}

/* Medium screen case */
@media only screen and (min-width: 1441px) and (max-width: 2000px) {
  .MainPane {
    min-width: 1046px;
    max-width: 1046px;
    width: 1046px;
  }
  .DetailPane {
    left: 1040px;
    width: calc(100vw - 1056px);
  }
}


