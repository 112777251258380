.InputWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-self: center;
}
.Input {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    border: none;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
    padding-top: 0px;
    text-align: right;
    width: 60px;
    background: transparent;
    /*background-color: rgba(0,0,0,0.07);*/
    text-align: center;
}

.Input:hover {
    margin-top: -1px;
    margin-bottom: -1px;
}
.Input:focus {
    outline: none;
}
.Input::placeholder {
    opacity: 0.3;
}

.Input:focus::placeholder {
    opacity: 0;
}

.Input[bigness='medium'] { width: 100px; } 
.Input[bigness='large'] { width: 200px; }
.Input[bigness='compact'] { width: 34px; }
