.Listing {
  text-align: left;
  /*border-top: 1px solid rgba(0, 0, 0, 0.07);*/
  /*box-sizing: border-box;*/
  border: none;
  font-weight: 400;
  font-size: 0.875em;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  transition: background-color 1s linear;
  min-height: 32px;
  padding: 0;
  transition: none;
  box-shadow: inset 0 -1px 0 0px rgba(0,0,0,0.1);
}

.Listing:hover {
  background-color: #f8f8ff;
  transition: none;
}

.Listing[actions='true'] {
}

.Listing[highlighted='true'] {
  box-shadow: inset 0 -1px 0 0px rgba(0,0,0,0.1), inset 4px 0px 0px 0px rgba(126,87,255,1.0); 
}

.Listing[new='true'] {
  animation: glow 3s;
}

.Listing[editing='true']:hover {
  background-color: white;
}

.Listing[selecting='true'] {
  background-color: #EFEBFFbb;
}

/*.Listing[selecting='true'] .Extra, .Listing:hover .Extra {
  background-color: #ffffff;
}*/


.Listing[selecting='true']:hover {
  background-color: #eae6fdbb;
}

.Listing[dimmed='true'] .Link {
  color: rgba(0,0,0,0.35);
}

@keyframes glow {
  0% { background-color: white; }
  20% { background-color: #CEFAE6; }
  80% { background-color: #CEFAE6; }
  100% { background-color: white; }
}

.Listing[highlighted='true'][new='true'] {
  background-color: #EFEBFF;
  animation: glowFromPurple 3s;
}

@keyframes glowFromPurple {
  0% { background-color: #EFEBFF; }
  20% { background-color: #CEFAE6; }
  80% { background-color: #CEFAE6; }
  100% { background-color: #EFEBFF; }
}

.Icon, .Close {
  padding-left: 20px;
  width: 30px;
  font-size: 1.2em;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.Close  {
  height: 10px;
  margin-top: 2px;
  vertical-align: middle;
  overflow: visible;
}

.Close svg {
  fill: rgba(0,0,0,0.2);
}

.Close:hover svg {
  fill: rgba(0,0,0,0.3);
}

.Icon:hover {
  animation: growIcon 0.75s ease-in-out;
}

@keyframes growIcon {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.IconAndTitle {
  white-space: nowrap;
  display: flex;
  align-content: top;
  justify-content: top;
  margin-top: 5px;
}

.Link {
  display: inline-block;
  color: black;
  text-decoration: none;
  cursor: pointer;
  margin-top: 2px;
  max-width: 530px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Listing[linking='true'] .Link:hover {
  color: #7E57FF;
}

.Chips {
  margin-top: 0.5em;
  margin-left: 50px;
  justify-self: right;
  align-self: right;
  margin-right: 2em;
  text-align: right;
}

.Extra {
  margin-left: 20px;
  justify-self: right;
  align-self: right;
  margin-right: 6px;
  text-align: right;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
}

.ExtraChild {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
}

.ExtraChild + .ExtraChild {
  display: flex;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Input {
  border: none;
  margin-top: -7px;
  margin-left: -2px;
  width: 35em;
  outline: none;
  font-size: 1em;
  display: flex;
  align-content: top;
  justify-content: top;
  background: rgba(0,0,0,0);
}

.Input::placeholder {
  color: rgba(0,0,0,0.35);
}

.Buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.Button {
  position: absolute;
  right: 2.1em;
  top: 2px;
}

.Radio {
  position: absolute;
  right: 7.9em;
  top: 2px;
}

.EnvironmentOptions {
  margin-right: 1em;
}

@media only screen and (max-width: 1024px) {
  .Chips {
    display: none;
  }

  .Input {
    max-width: 400px;
  }
}

@media only screen and (max-width: 480px) {
  .Chips {
    display: none;
  }

  .Icon {
    vertical-align: top;
  }

  .Link {
    max-width: 300px;
    margin-top: 2px;
    margin-bottom: 0.6em;
    overflow: default;
    white-space: normal;
  
  }

  .Input {
    border: none;
    width: 50em;
    max-width: 300px;
    outline: none;
    font-size: 1em;
  }
  
  
}


