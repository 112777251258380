.RadioItem {
  border-radius: 20px;
  background-color: #FFFFFF;
  display: inline-block;
  position: relative;
  overflow: hidden;
  color: black;
  color: rgba(127,127,127,1.0);
  border: 1px solid rgba(0,0,0,0.25);
  /*box-shadow: rgba(0, 0, 0, 0.424) 0px 0px 1px, rgba(0, 0, 0, 0.067) 0px 1px 1px, rgba(0, 0, 0, 0.024) 0px 2px 2px, rgba(0, 0, 0, 0.024) 0px 4px 4px;*/
  transition: border-color 0.25s linear, background-color 0.25s linear, box-shadow 0.25s linear;
  cursor: pointer;
  user-select: none;
  padding: 0em 0.9em 0em 0.9em;
  margin-top: 1px;
  vertical-align: middle;
  line-height: 24px;
  height: 24px;  
  font-size: 0.875em;
  font-weight: 500;
}

.RadioItem[winner='true'] {
  background-color: #DFD6FF;
  border-color: #DFD6FF;
  color: #7E57FF;
}

.RadioItem + .RadioItem {
  margin-left: 0.5em;
}