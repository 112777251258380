.Container {
  position: relative;
  display: flex;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  transition: opacity 0.2s ease;
}

.Image,
.Overlay {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Image {
  margin-bottom: -2px;
  margin-right: 5px;
}

.Unassigned {
  background-color: #FFE6E6;
  color: #FF5757;
  font-size: 12px;
  font-weight: bold;
}

.Overlay {
  background-color: #DFD6FF;
  color: #7E57FF;
  font-size: 10px;
  font-weight: bold;
  opacity: 0;
  /*transition: opacity 0.2s;*/
}

.UnassignedOverlay {
  background-color: #FF5757;
  color: white;
}

.Overlay:hover {
  opacity: 1;
}


.Tooltip {
  position: absolute;
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 8px;
  text-align: right;
  z-index: 1000;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.Tooltip::after {
  content: '';
  position: absolute;
  right: -7.5px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}

.Tooltip::before {
  content: '';
  position: absolute;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.15);
  z-index: -1;
}